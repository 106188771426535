import dayjs from "dayjs";
import HMS from "./Hms";
import { v4 as uuid } from "uuid";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc)

export const generateFileName = (file: File) => `${uuid().replace("-", "")}.${file.name.split(".").pop()}`;

// Fisher-Yates shuffle
export const shuffleArray = (array: any[]): any[] => {
  const res = Array.from(array);
  res.forEach((v,i,a) => {
    const r = a.length - 1 - Math.trunc(Math.random() * (a.length - i));
    [ a[i], a[r] ] = [ a[r], a[i] ];
  });
  return res;
};

// not perfectly random: https://stackoverflow.com/a/53758827
export const shuffleArrayWithSeed = (array: any[], seed: number): any[] => {
  const res = Array.from(array);
  res.forEach((v,i,a) => {
    const r = a.length - 1 - Math.trunc(random(seed + i) * (a.length - i));
    [ a[i], a[r] ] = [ a[r], a[i] ];
  });
  return res;
}

const random = (seed: number): number => {
  const rnd = Math.sin(seed) * 10000;
  return rnd - Math.floor(rnd);
}

export const filterObjectProps = (object: object, props: string[], remove?: boolean) => (remove 
    ? Object.entries(object).filter(([key, _]) => !props.includes(key))
    : Object.entries(object).filter(([key, _]) => props.includes(key))
  ).reduce((aggr, [key, value]) => ({...aggr, [key]: value}), {});

// https://stackoverflow.com/questions/201183/how-can-i-determine-equality-for-two-javascript-objects#32922084
export const deepEqual = (x: any, y: any): boolean => {
  const ok = Object.keys, tx = typeof x, ty = typeof y;
  return x && y && tx === 'object' && tx === ty ? (
    ok(x).length === ok(y).length &&
      ok(x).every(key => deepEqual(x[key], y[key]))
  ) : (x === y);
}

export const boostSeconds = (seconds: number, boost: number) => (boost && boost > 0
  ? Math.round(((100 + boost) * seconds) / 100)
  : seconds);

export const unboostSeconds = (boostedSeconds: number, boost: number) => (boost && boost > 0
  ? Math.round(((100) * boostedSeconds) / (100 + boost))
  : boostedSeconds);


// days until
export const daysUntil = (date: Date | dayjs.Dayjs) => {
  // Get number of days and make sure to round up
  return Math.ceil(dayjs(date).diff(dayjs(), "days", true));
}

export const secondsToHms = (seconds: number): HMS => new HMS(seconds);

export const hmsToSeconds = (hms: HMS): number => hms.toSeconds();

export const generateColor = (seed: string, transparency?: number, highlight?: boolean) => {
  const a = seed.split("").reduce((acc, curr) => acc + curr.charCodeAt(0), "");
  const i = parseInt(a, 10) % 16777215;
  //any hue, 50-100% saturation, 35-50 lightness
  return highlight
  ? `hsl(${i % 360}, ${ (i % 51) + 50}%, ${(i % 16) + 50}%, ${transparency || 1})`
  : `hsla(${i % 360}, ${ (i % 51) + 50}%, ${(i % 16) + 35}%, ${transparency || 1})`;

};

export const range = (start: number, end: number) => Array.from(
  Array(Math.abs(end - start) + 1),
  (_, i) => start + i,
);

export const roundToNDigits = (v: number, digits: number) => {
  const multiplier = 10 ** digits;
  const r = Math.round((v * multiplier) * 10) / 10;
  return Math.round(r) / multiplier;
};