import React, { memo } from "react";

export interface HeaderProps {
  /** Username of the other user in the chat */
  pseudo?: string;
  /** Status of the other user in the chat. If null or undefined, the status will be hidden. */
  active?: boolean;
  /** PRofile picture */
  picture?: string;
  /** Hide indicator */
  hideIndicator?: boolean;
  /** Customisable slots */
  slots?:{
    action?: React.ReactNode;
    description?: React.ReactNode;

  }
}

const Header: React.FC<HeaderProps> = ({
  pseudo, active, picture, hideIndicator, slots: {
    action = null,
    description = null,
  } = {},
}) => (
  <div className="flex justify-between p-3 border-b border-gray-300 bg-gray-50">
    <div className="flex flex-col justify-center gap-y-2">
      <div className="flex items-center space-x-2">
        {typeof active === "boolean" && !picture && !hideIndicator && (
          <div>
            <div className={`w-2 h-2 rounded-full ${active ? "bg-green-500" : "bg-red-600 animate-pulse"}`} />
          </div>
        )}
        {
          picture && (
            <img
              src={picture}
              className="w-12 h-12 rounded-full"
              alt="profile"
            />
          )
        }
        <div className="font-medium">{pseudo}</div>
      </div>
      {description}
    </div>
    {action}
  </div>
);

export default Header;
