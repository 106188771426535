/* eslint-disable consistent-return */
import React, {
  PropsWithChildren, useEffect, useMemo, useState,
} from "react";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import { toast } from "react-toastify";
import { retry } from "rxjs";

export interface LivechatIncommingMessage {
  origin: string;
  subject: string;
  event: string;
  ts: number;
  payload: LivechatMessage | LivechatRoomStatus | string;
}

export interface LivechatMessage {
  type: "message" | "authentify" | "writingStatus" | "finish";
  kind?: "text" | "attachment";
  content: string;
}

export interface LivechatMessageItem {
  origin: string;
  ts: number;
  payload: LivechatMessage;
}

export type LivechatRoomStatus = "CREATED" | "OPEN" | "CONNECTED" | "INITIATED" | "STARTED" | "FINISHED" | "EXPIRED" | "CANCELED" | "CONNECTION_LOST";

export interface LivechatRoomInfo {
  livechatRoomUid: string;
  participants: LivechatRoomParticipant[];
  maxTime: number;
  application: string;
  messages: LivechatMessageItem[];
  createdAt: string;
  startDate: string;
  endDate?: string;
  status: LivechatRoomStatus;
}

export interface LivechatRoomParticipant {
  name: string;
  userUid: string;
  participantType: string;
  firstMessageAt?: string
  lastMessageAt?: string
  closedAt?: string
  connectionEvents: {
    connected: boolean;
    ts: string;
  }[]
}

export const livechatRoomIsClosed = (status: string) => ["FINISHED", "EXPIRED", "CANCELED"].includes(status);
export const livechatRoomIsOpen = (status: string) => ["CONNECTED", "INITIATED", "STARTED", "CONNECTION_LOST"].includes(status);

interface Props {
  userUid: string;
  wsBaseHost: string;
  userToken: () => Promise<string>;
  uploadFile?: (file: File, share: string[]) => Promise<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: (key: string, params?: any) => string
}

interface LivechatContextType {
  livechatRoom?: LivechatRoomInfo;
  livechatRoomStatus?: LivechatRoomStatus;
  livechatMessages: LivechatMessageItem[];
  participantsWriting: string[];
  openLivechatRoom: (livechatRoomInfo: LivechatRoomInfo) => void;
  sendMessage: (content: string) => void;
  sendAttachment: (file: File) => Promise<void>;
  closeLivechatRoom: () => void;
  sendWritingStatus: (writing: boolean) => void;
  setLastSeen: (ts?: number) => void;
  unreadMessages: number;
}

const LivechatContext = React.createContext<LivechatContextType>({
  livechatMessages: [],
  participantsWriting: [],
  openLivechatRoom: () => ({}),
  sendMessage: () => ({}),
  sendAttachment: () => Promise.resolve(),
  closeLivechatRoom: () => ({}),
  sendWritingStatus: () => ({}),
  setLastSeen: () => ({}),
  unreadMessages: 0,
});

const LivechatProvider: React.FC<PropsWithChildren<Props>> = ({
  userUid, wsBaseHost, userToken, uploadFile, t, children,
}) => {
  const [subject, setSubject] = useState<WebSocketSubject<LivechatIncommingMessage | LivechatMessage>>();
  const [ts, setTS] = useState(0);
  const [livechatRoom, setLivechatRoom] = useState<LivechatRoomInfo>();
  const [livechatRoomStatus, setStatus] = useState<LivechatRoomStatus>();
  const [livechatMessages, setLivechatMessages] = useState<LivechatMessageItem[]>([]);
  const [lastSeenTs, setLastSeenTs] = useState<number>(0);
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const [participantsWriting, setWriting] = useState<string[]>([]);

  useEffect(() => {
    if (!livechatRoom) return;
    if (!livechatRoomIsClosed(livechatRoom.status)) {
      const sub = webSocket<LivechatIncommingMessage | LivechatMessage>(
        {
          url: `${wsBaseHost}/rtc-api/livechat/${livechatRoom.livechatRoomUid}`,
        },
      );
      sub.pipe(retry({ delay: 2000 })).subscribe({
        next: (msg) => handleMessage(msg as LivechatIncommingMessage),
        error: (err) => toast.error(t("common:errors.wsError"), { autoClose: false }),
      });
      setSubject(sub);
      return () => sub.unsubscribe();
    }
    return () => ({});
  }, [livechatRoom?.livechatRoomUid]);

  useEffect(() => {
    if (!livechatRoomStatus) return;
    switch (livechatRoomStatus) {
      case "CANCELED":
        resetLivechatRoom();
        break;

      default:
        break;
    }
  }, [livechatRoomStatus]);

  useEffect(() => {
    if (ts && subject) {
      userToken().then((token) => subject.next({ type: "authentify", content: token }));
    }
  }, [ts]);

  useEffect(() => {
    if (livechatMessages.length === 0 || lastSeenTs === 0) {
      setUnreadMessages(0);
    } else {
      const unread = livechatMessages.filter((m) => m.origin !== userUid && m.ts > lastSeenTs).length;
      setUnreadMessages(unread);
    }
  }, [livechatMessages, lastSeenTs]);

  const openLivechatRoom = (livechatRoom: LivechatRoomInfo) => {
    setLivechatRoom(livechatRoom);
    setLivechatMessages(livechatRoom.messages);
    setStatus(livechatRoom.status);
  };

  const closeLivechatRoom = () => {
    if (!subject) return;
    subject.next({ type: "finish", content: new Date().getTime().toString() });
    resetLivechatRoom();
  };

  const resetLivechatRoom = () => {
    setLivechatRoom(undefined);
    setLivechatMessages([]);
    setTS(0);
    setStatus(undefined);
    setLastSeenTs(0);
  };

  const handleWritingUpdates = (event: LivechatIncommingMessage) => {
    if ((event.payload as string) === "true") {
      setWriting((p) => [...p, event.origin]);
    } else {
      setWriting((p) => p.filter((i) => i !== event.origin));
    }
  };

  const handleMessage = (msg: LivechatIncommingMessage) => {
    switch (msg.event) {
      case "livechat.status.changed":
        setStatus(msg.payload as LivechatRoomStatus);
        break;
      case "server.ready":
        setTS(msg.ts);
        break;
      case "livechat.writingStatus":
        handleWritingUpdates(msg);
        break;
      case "livechat.message":
        setLivechatMessages((prev) => [
          ...prev,
          { origin: msg.origin, ts: msg.ts, payload: JSON.parse(msg.payload as string) },
        ]);
        break;
      default:
        break;
    }
  };

  const sendMessage = (content: string) => {
    const message: LivechatMessage = {
      type: "message",
      kind: "text",
      content,
    };
    subject?.next(message);
    setLivechatMessages((prev) => [
      ...prev,
      { origin: userUid, ts: new Date().getTime(), payload: message },
    ]);
  };

  const sendAttachment = async (file: File) => {
    if (!livechatRoom || !uploadFile) return;
    const url = await uploadFile(file, livechatRoom.participants.filter((p) => p.userUid !== userUid).map((p) => p.userUid));
    const message: LivechatMessage = {
      type: "message",
      kind: "attachment",
      content: url,
    };
    subject?.next(message);
    setLivechatMessages((prev) => [
      ...prev,
      { origin: userUid, ts: new Date().getTime(), payload: message },
    ]);
  };

  const sendWritingStatus = (writing: boolean) => {
    const message: LivechatMessage = {
      type: "writingStatus",
      content: `${writing}`,
    };
    subject?.next(message);
  };

  const value = useMemo(() => ({
    livechatRoom,
    livechatRoomStatus,
    livechatMessages,
    participantsWriting,
    openLivechatRoom,
    sendMessage,
    sendAttachment,
    closeLivechatRoom,
    sendWritingStatus,
    setLastSeen: (ts?: number) => setLastSeenTs(ts || 0),
    unreadMessages,
  }), [
    livechatRoom, livechatRoomStatus, livechatMessages, participantsWriting,
    openLivechatRoom, sendMessage, sendAttachment, closeLivechatRoom, sendWritingStatus,
    unreadMessages,
  ]);

  return (
    <LivechatContext.Provider value={value}>
      {children}
    </LivechatContext.Provider>
  );
};

const useLivechat = () => React.useContext(LivechatContext);

export default LivechatContext;
export { LivechatProvider, useLivechat };
